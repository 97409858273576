

export const BASE_URL = "http://usdc.wim-x.com";

export const StorageKeys = {
    PERSISTED_STATE: '_p',
    APP_SETTINGS: '_a',
    TOKEN: '_t'
}


export enum Theme {
    Auto = 0,
    Light = 1,
    Dark = 2
}
export const drawerWidth = 240;

export const updateTimer = 1 * 1000;

export enum GraphType {
    None = '',
    Spline = 'spline',
    Bar = 'bar',
    BarColumn = 'column',
    Line = 'line'
}

// export interface SensorProps extends SensorConfig {

// }


// export interface DeviceProps extends DevicesConfig {

// }


export enum IUserType {
    Default = 0,
    User = 1,
    Admin = 99,
    SuperAdmin = 100
}


export const DASHBOARD_BG = '#1b2342';
