import { AuthState } from "./iAuth";

const logger = (reducer: any) => {
    const reducerWithLogger = (state: AuthState, action: any) => {

        return reducer(state, action);
    };
    return reducerWithLogger;
};

export default logger;
