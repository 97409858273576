
import IconDashboard from '@mui/icons-material/Dashboard'
import IconAccessAlarm from '@mui/icons-material/AccessAlarm'
import IconPeople from '@mui/icons-material/People'
import IconSettings from '@mui/icons-material/Settings'
import IconBarChart from '@mui/icons-material/BarChart'
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import IconLibraryBooks from '@mui/icons-material/LibraryBooks'
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';


export const RouterLinks = {
    DEFAULT: '/',
    LOGIN: '/login',
    DASHBOARD: '/dashboard',
    ALARMS: '/alarm',
    SETTINGS: '/settings',
    DEVICES: '/devices',
    REPORTS: '/reports',
    FORGOT_PASSWORD: '/forgot-password'
}


export const appMenuItems = [
    {
        name: 'Dashboard',
        link: RouterLinks.DASHBOARD,
        Icon: IconDashboard,
    },
    {
        name: 'Alarm',
        link: RouterLinks.ALARMS,
        Icon: IconAccessAlarm,
    },
    {
        name: 'Reports',
        link: RouterLinks.REPORTS,
        Icon: IconBarChart,
    },
    {
        name: 'Devices',
        link: RouterLinks.DEVICES,
        Icon: DevicesOtherIcon,
    },
    {
        name: 'Settings',
        link: RouterLinks.SETTINGS,
        Icon: IconSettings,
    },
    // {
    //     name: 'Admin',
    //     Icon: IconLibraryBooks,
    //     items: [
    //         {
    //             name: 'Add ',
    //             link: `${RouterLinks.ADMINS}`,
    //         },
    //         {
    //             name: 'Level 2',
    //             items: [
    //                 {
    //                     name: 'Level 3',
    //                 },
    //                 {
    //                     name: 'Level 3',
    //                 },
    //             ],
    //         },
    //     ],
    // },
]

export interface RouteUrls {
    // icon?: string | OverridableComponent<SvgIconTypeMap>;
    icon: string;
    name: string;
    urlPath?: string;
    access: boolean
    childrens?: RouteUrls[]
}


export const ROUTE_URLS: RouteUrls[] = [
    {
        icon: `${AccountCircle.name}`,
        name: 'Dashboard',
        urlPath: RouterLinks.DASHBOARD,
        access: true,
    },
    {
        icon: `${AccountCircle.name}`,
        name: 'Alarm',
        urlPath: RouterLinks.ALARMS,
        access: true,
    },
    // {
    //     icon: `${AccountCircle.name}`,
    //     name: 'Admins',
    //     urlPath: RouterLinks.ADMINS,
    //     access: true,
    //     childrens: [
    //         {
    //             icon: `${SearchIcon.name}`,
    //             name: 'Add Admins',
    //             urlPath: `${RouterLinks.ADMINS}`,
    //             access: true,
    //         }
    //     ]
    // }
]
