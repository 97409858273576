import React from 'react'

import List from '@mui/material/List'


import { createStyles, makeStyles } from '@mui/styles';
import { createTheme, Palette, PaletteOptions, Theme, ThemeProvider, withStyles } from '@mui/material/styles';
import { Spacing } from '@mui/system';

import AppMenuItem from './AppMenuItem'
import { appMenuItems, RouterLinks } from '@routes/route-uls'
import { TransitionGroup } from 'react-transition-group'


const AppMenu: React.FC = () => {
    const classes = useStyles()

    return (
        <List component="nav" className={classes.appMenu} disablePadding>
            <TransitionGroup>
                {/* <AppMenuItem {...appMenuItems[0]} /> */}
                {appMenuItems.map((item, index) => (
                    <AppMenuItem {...item} key={index} />
                ))}
            </TransitionGroup>
        </List>
    )
}

const drawerWidth = 240

const useStyles = makeStyles(theme =>
    createStyles({
        appMenu: {
            width: '100%',
        },
        navList: {
            width: drawerWidth,
        },
        menuItem: {
            width: drawerWidth,
        },
        menuItemIcon: {
            color: '#97c05c',
        },
    }),
)

export default AppMenu
